import { render, staticRenderFns } from "./ClimaActions.vue?vue&type=template&id=2ba87948&scoped=true&"
import script from "./ClimaActions.vue?vue&type=script&lang=js&"
export * from "./ClimaActions.vue?vue&type=script&lang=js&"
import style0 from "./ClimaActions.vue?vue&type=style&index=0&id=2ba87948&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ba87948",
  null
  
)

export default component.exports